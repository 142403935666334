@import "../../scss/partials/colors";

.api-route-comp {
    padding: 50px 0;
    display: flex;
    border-top: solid 1px $swiipe-prim;

    .section {
        font-size: 22px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .left {
        flex: 1 1 0;
        margin-right: 3%;

        .route-name {
            margin-bottom: 10px;
        }
    }
    .right {
        flex: 1 1 0;
        margin-left: 3%;
    }

    .example {
        margin-bottom: 35px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
