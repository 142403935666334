@import "../../scss//partials/colors";

.swiipe-box {
    .swiipe-box-title {
        font-weight: bold;
        background-color: $swiipe-prim;
        color: white;
        padding: 10px;
        border-radius: 5px 5px 0 0;
    }
    .swiipe-box-content {
        background-color: $swiipe-sec-light;
        padding: 20px 10px;
    }

    &.invert {
        .swiipe-box-title {
            background-color: $swiipe-sec;
        }
        .swiipe-box-content {
            background-color: white;
        }
    }
}
