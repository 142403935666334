.content-wrapper-comp {
    .toolbar {
        display: flex;
        justify-content: space-between;
    }

    .actions {
        flex: 0 0 auto;
        button {
            float: left;
            margin-right: 10px;
            width: 100px;
        }
    }

    .languages {
        flex: 0 0 auto;
    }

    .language {
        float: left;
    }
}
