@import "../../scss/partials/variables.scss";
@import "../../scss/partials/colors.scss";

.api-route-group-comp {
    background-color: white;
    border: solid 1px $swiipe-prim;
    margin: 30px 4%;
    padding: 3% 3%;
    border-radius: 7px;

    .column-2 {
        display: flex;

        .intro {
            flex: 1 1 0;
            margin-right: 3%;
        }

        .title {
            font-size: 30px;
            min-height: 50px;
        }

        .description-right {
            flex: 1 1 0;
            margin-left: 3%;
        }

        .routes-info {
            flex: 1 1 0;
            min-height: 70px;
            margin-left: 3%;
        }
    }

    .routes {
        margin-top: 100px;
        width: 100%;
    }

    @media screen and (max-width: $breakpoint-large) {
        margin: 15px 0;

        .intro {
            flex: 1 1 100%;
            padding-right: 0;
        }
        .rotes-info {
            flex: 1 1 100%;
            padding-left: 0;
        }
    }

    .endpoints {
        margin-left: 20px;
        font-size: 14px;

        .route-name {
            margin-bottom: 3px;
            cursor: pointer;
        }
    }
}
