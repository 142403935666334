@import "../../scss/partials/all.scss";

.code-editor {
    .editor {
        border: 1px solid lightgrey;

        line-height: 1.5;
        font-size: 12px;
        overflow-y: auto !important;

        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    }
    .editor-textarea {
        outline: 0;
    }

    &.horizontal-scroll {
        .editor {
            overflow-x: auto !important;
        }
        pre {
            white-space: pre !important;
            display: inline-block !important;
            overflow-x: auto !important;
        }
    }
}
