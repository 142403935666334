@import "../../scss/partials/all.scss";

#top-navbar {
    #user-role-dropdown {
        margin-left: auto !important;

        .dropdown-item {
            height: $nav-bar-height;
        }
    }

    .navbar-dropdown .nav-link,
    .nav-item {
        font-size: 16px;
        font-weight: 700;
        color: $blue-main;
    }

    .navbar-dropdown {
        .dropdown-menu {
            border-radius: 5px !important;
            border-color: $gray-background !important;
            left: unset;

            .dropdown-item {
                height: $nav-bar-height;
                font-size: 16px;
                font-weight: 700;
                color: $blue-main;
                display: flex;
                align-items: center;
            }
        }
    }

    .dropdown-item {
        .icon {
            margin-left: 10px;
        }
    }

    .account-name {
        line-height: 36px;
        display: inline-block;
        vertical-align: top;
        margin-left: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
        padding-right: 15px;
    }

    .icon-nav-user {
        display: inline-block;
    }

    .icon-nav-manager {
        display: inline-block;
    }

    @media screen and (max-width: $breakpoint-tables) {
        .dropdown-toggle {
            .account-name {
                display: none;
            }
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        .dropdown-item {
            padding: 0 !important;

            .icon {
                margin-left: 20px;
                width: 25px !important;
                height: 25px !important;
                background-size: 25px !important;
            }
        }

        .account-name {
            max-width: 200px;
            font-weight: 400;
            font-size: 0.85em;
        }
    }
}
