.api-home-page {
    display: flex;
    .menu {
        width: 230px;
    }
    .content {
        flex: 1 1 0;
        overflow: hidden;
    }
}
