.swiipe-page-container {
    .center-content {
        max-width: 472px;
        margin: 0 auto;
    }
    .page-body.large-text {
        p {
            font-size: 16px;
        }
        div {
            font-size: 16px;
        }
    }
    .text-smaller {
        font-size: 0.75em;
    }
}
