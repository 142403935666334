.api-route-param {
    margin-bottom: 20px;
    .in {
        color: #111;
        font-style: italic;
        padding-right: 5px;
    }
    .name {
        color: #111;
        font-weight: bold;
    }
    .type {
        padding-left: 5px;
        color: #666;

        .array {
            color: #333;
        }
        .component {
            color: green;
        }
        .object {
            color: #666;
        }
        .string {
            color: #666;
        }
    }
    .required {
        font-weight: bold;
        padding-left: 5px;
        color: orange;
    }
    .optional {
        font-weight: bold;
        padding-left: 5px;
        color: #666;
    }
    .desc {
        margin-top: 3px;
    }
    .sub-items {
        margin-top: 10px;
    }
}
