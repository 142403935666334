@import "../../scss/partials/variables.scss";
@import "../../scss/partials/colors.scss";

.api-menu-comp {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 230px;
    background-color: white;

    .logo {
        padding-bottom: 20px;
        img {
            width: 100%;
        }
    }

    .api-updater {
        margin: 20px;

        .last-updated {
            font-size: 12px;
            text-align: center;
            padding: 10px 0;
        }
    }

    .group {
        padding-left: 20px;
        font-size: 15px;
        font-weight: bold;
        color: $swiipe-prim;
        margin-bottom: 5px;

        .group-name {
            cursor: pointer;
        }

        .routes {
            padding-top: 5px;
            margin-bottom: 15px;
        }

        .route {
            font-size: 12px;
            font-weight: bold;
            color: $swiipe-prim;
            cursor: pointer;

            &.selected {
                color: $swiipe-sec;
            }
        }

        &.selected {
            margin-top: 10px;
            color: $swiipe-sec;
        }
        &.route-selected {
            margin-top: 10px;
        }
    }
}
