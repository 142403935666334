@import "../../../scss/partials/all.scss";

.form-label-group {
    position: relative;
    margin-bottom: 1rem;

    input {
        padding: $input-padding-y $input-padding-x;
    }

    textarea {
        min-height: 6em;
        padding: calc(#{$input-padding-y} * 0.6) $input-padding-x;
        padding-top: calc(#{$input-padding-y} * (2 / 3));
        padding-bottom: calc(#{$input-padding-y} / 3);
    }

    input.placeholder-hidden,
    textarea.placeholder-hidden {
        font-weight: bold;
        padding-top: calc(#{$input-padding-y} * (2 / 3));
        padding-bottom: calc(#{$input-padding-y} / 3);
    }

    textarea.placeholder-hidden {
        padding-top: calc(#{$input-padding-y} * (2 / 3));
        padding-bottom: calc(#{$input-padding-y} / 3);
    }

    input.placeholder-hidden ~ label {
        padding-top: calc(#{$input-padding-y} / 3);
        padding-bottom: calc(#{$input-padding-y} / 3);
        font-size: var(--input-size);
        color: $gray-font;
    }

    input.placeholder-hidden,
    textarea.placeholder-hidden {
        ~ span:after {
            top: $input-top;
            font-size: $input-size;
        }
    }

    input.placeholder-hidden,
    textarea.placeholder-hidden {
        ~ span {
            opacity: 1; // Added to make sure IE/Edge will repaint after adding class to span
        }
    }

    .extra-label {
        font-size: 16px;
        color: $gray-font;
        padding-left: 10px;
        right: -5px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        border-left: 1px solid;
    }

    input:-webkit-autofill {
        font-weight: bold;
        padding-top: calc(#{$input-padding-y} * (2 / 3));
        padding-bottom: calc(#{$input-padding-y} / 3);

        & + span:after {
            top: $input-top;
            font-size: $input-size;
        }
    }

    span {
        font-size: 16px;

        &::after {
            user-select: none;
            -moz-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -o-user-select: none;
            pointer-events: none;
            content: attr(data-value);
            position: absolute;
            display: block;
            color: $gray-font;
            top: $base--line-height * 0.3;
            left: $base--spacing * 0.5;
            line-height: $input-line-height;
        }
    }

    span.initialized {
        &::after {
            transition: 0.25s all ease;
        }
    }

    .no-select {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }

    .input-group {
        .password-input {
            border-right: none;
            border-top-right-radius: 0% !important;
            border-bottom-right-radius: 0% !important;
        }

        .show-password {
            border: 1px solid $gray-border;
            border-left: none;
            border-top-right-radius: 3%;
            border-bottom-right-radius: 3%;
            padding: 0.375rem 0.75rem;
            position: relative;
        }

        .show-password-text {
            color: $gray-border;

            &::after {
                display: block;
                position: absolute;
                content: "";
                height: 50%;
                top: 25%;
                left: 0;
                border: 0.5px solid $gray-border;
            }
        }
    }

    .input-group .form-control:focus {
        z-index: 0;
    }

    input[type="password"]::-ms-reveal,
    input[type="password"]::-ms-clear {
        display: none;
    }
}
