.swiipe-spinner-container {
    position: relative;

    .child-wrapper {
        display: inline;
        &.hide {
            visibility: hidden;
        }
    }
    .spinner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
}
