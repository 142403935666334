@import "../../scss//partials/colors";

.swiipe-frame {
    .swiipe-frame-content {
        display: inline-block;
        border-radius: 7px;
        border: solid 3px $swiipe-prim;
        background-color: $swiipe-sec-light;
        padding: 5px 10px;
    }

    &.invert {
        .swiipe-frame-content {
            border-color: $swiipe-sec;
            background-color: white;
        }
    }

    &.blackwhite {
        .swiipe-frame-content {
            border-color: black;
            background-color: white;
        }
    }
}
