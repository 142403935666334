@import "../../scss//partials//colors";

.api-route-sub-params-container {
    display: inline-block;

    &.open {
        display: block;

        .title {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .title {
        cursor: pointer;
        font-size: 10px;
        padding: 3px 5px;
        border-radius: 7px;
        background-color: $swiipe-prim;
        color: white;
    }

    .params {
        padding: 10px 5px;
        border: solid 1px $swiipe-prim;
        border-top-width: 0;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
    }
}
