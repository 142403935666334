@import "../../scss/partials/all.scss";

.content-editor-actions-comp {
    .actions {
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;

        .editor-button {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .language_ddl {
            margin-bottom: 20px;
        }
        .save-button {
            margin-bottom: 20px;
        }
    }
}
