#main-container.full-width {
    padding: 10px !important;
    max-width: inherit !important;

    &.no-padding {
        padding: 0px !important;
    }
}

#main-container.medium-width {
    padding: 10px !important;
    max-width: 900px !important;

    &.no-padding {
        padding: 0px !important;
    }
}
