@import "../../scss/partials/variables.scss";
@import "../../scss/partials/colors.scss";

.api-route-param-group-comp {
    .title {
        font-weight: bold;
    }
    .sub-section {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .description {
        margin-bottom: 15px;
    }

    .no-params {
        font-style: italic;
    }

    .parameters {
        margin-top: 40px;
    }

    .toggle-open {
        font-size: 10px;
        text-decoration: underline;
        cursor: pointer;
    }

    .toggle-params {
        margin-left: 20px;
        font-size: 10px;
        text-decoration: underline;
        cursor: pointer;
    }
}
