@import "../../scss/partials/_all.scss";

.swiipe-user-relation-search-box {
    background-color: white;
    margin: 20px 0;
    padding: 15px;

    input {
        background: url("../../images/icons/search-icon.svg") no-repeat right 10px center;
        padding-right: 30px;

        &::placeholder {
            color: $gray-font;
            font-weight: 100;
        }
    }
}
