@import "../../scss//partials/colors";

.resolved-content-comp {
    .tag {
        font-size: 12px;
        border: solid 1px #333;
        padding: 2px 3px;
        margin-left: 4px;
        border-radius: 6px;
        background-color: #fff;
    }
}
