.page-admin-page {
    .page-item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        justify-content: space-between;

        .locator {
            margin-left: 0;
        }

        & > * {
            margin: 0 7px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }

        .new-page {
            width: 100%;
        }

        .locator {
            flex: 1 1 0;
        }

        .save {
            flex: 0 0 80px;
        }

        .delete {
            flex: 0 0 80px;
            text-transform: initial;
        }

        .goto {
            flex: 0 0 80px;
        }
    }
}
