.api-route-name {
    font-size: 14px;
    word-break: break-all;

    &.align-rows {
        .method {
            display: inline-block;
            min-width: 59px;
            text-align: right;
        }
    }

    .method {
        padding-right: 6px;
        &.get {
            color: green;
        }
        &.post {
            color: blue;
        }
        &.put {
            color: blue;
        }
        &.delete {
            color: orangered;
        }
    }
}
