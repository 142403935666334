/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */
.code-editor-json-default {
    .editor {
        background: none;
    }

    code[class*="language-"],
    pre[class*="language-"] {
        color: #ccc;
        background: none;
        font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
        font-size: 1em;
        text-align: left;
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;
        line-height: 1.5;

        -moz-tab-size: 4;
        -o-tab-size: 4;
        tab-size: 4;

        -webkit-hyphens: none;
        -moz-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;
    }

    /* Code blocks */
    pre[class*="language-"] {
        padding: 1em;
        margin: 0.5em 0;
        overflow: auto;
    }

    :not(pre) > code[class*="language-"],
    pre[class*="language-"] {
        background: #2d2d2d;
    }

    /* Inline code */
    :not(pre) > code[class*="language-"] {
        padding: 0.1em;
        border-radius: 0.3em;
        white-space: normal;
    }

    .token {
        &.comment,
        &.block-comment,
        &.prolog,
        &.doctype,
        &.cdata {
            color: #4f566b;
        }

        &.punctuation {
            color: #8792a2;
        }

        &.tag,
        &.attr-name,
        &.namespace,
        &.deleted {
            color: #e2777a;
        }

        &.function-name {
            color: #6196cc;
        }

        &.number,
        &.function {
            color: #e56f4a;
        }

        &.boolean {
            color: #7e1e23;
        }

        &.property,
        &.class-name,
        &.constant,
        &.symbol {
            color: #70837e;
        }

        &.selector,
        &.important,
        &.atrule,
        &.keyword,
        &.builtin {
            color: #cc99cd;
        }

        &.string,
        &.char,
        &.attr-value,
        &.regex,
        &.variable {
            color: #09825d;
        }

        &.entity,
        &.url {
            color: #67cdcc;
        }

        &.operator {
            color: #2f3d89;
        }

        &.null {
            color: #8792a2;
        }

        &.important,
        &.bold {
            font-weight: bold;
        }
        &.italic {
            font-style: italic;
        }

        &.entity {
            cursor: help;
        }

        &.inserted {
            color: green;
        }
    }
}
