@import "../../scss/partials/all.scss";

.content-page-container-comp {
    @media screen and (min-width: 900px) {
        margin-left: 30px;
        margin-right: 30px;
    }
    .content-page {
        font-size: 16px;

        h1 {
            margin: 30px auto 40px auto;
            max-width: 500px;
            text-align: center;
        }
        h3 {
            margin-top: 30px;
            margin-bottom: 20px;
        }

        p {
        }
    }
}
