@import "../../scss/partials/_all.scss";

.swiipe-user-relation-info {
    background-color: white;
    margin: 20px 0;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .btn-switch {
        font-weight: 400;
        width: 100px;
        height: 40px;
    }

    .text-above-button {
        color: $red-error;
        text-align: right;
    }

    .userrelation {
        flex: 1;

        .name {
            font-size: 1.2em;
            color: $blue-main;
        }

        .parentname {
            font-size: 1.08em;
            font-style: italic;
            color: $gray-font;
        }
    }

    .swiipe-spinner-container {
        display: block;
    }

    .action-box {
        display: block;
        float: right;
        min-width: 127px;

        .swiipe-btn {
            margin: 0 auto;
            display: block;
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        .div-fullwidth {
            flex-basis: 100%;
        }
    }
}
